import { ApiClientV2, Client } from '../clients';

export class VisitorsClient {
  async getSettingsVisitors(officeId) {
    try {
      return await ApiClientV2.get(`/visitors/types-and-purposes`);
    } catch (error) {
      throw error;
    }
  }

  async getVisitors(params) {
    try {
      return await ApiClientV2.get(`/admin/visitors/log`, params);
    } catch (error) {
      throw error;
    }
  }

  async searchVisitorEmail(params) {
    try {
      return await ApiClientV2.get(`/admin/visitors/log/email`, params);
    } catch (error) {
      throw error;
    }
  }

  async createVisitor(params) {
    try {
      return await ApiClientV2.post(`/admin/visitors`, params);
    } catch (error) {
      throw error;
    }
  }

  async editVisitor(id, params) {
    try {
      return await ApiClientV2.patch(`/admin/visitors/${id}`, params);
    } catch (error) {
      throw error;
    }
  }

  async getOffices() {
    try {
      return await Client.get('/v1/offices');
    } catch (error) {
      throw error;
    }
  }

  async getBuildings() {
    try {
      return await Client.get('/v1/buildings');
    } catch (error) {
      throw error;
    }
  }

  async getLegalDocsSigning(params) {
    try {
      return await ApiClientV2.get('/admin/legal-documents/signing', params);
    } catch (error) {
      throw error;
    }
  }

  async getLegalDocsOffice(officeId) {
    try {
      return await ApiClientV2.get('/admin/legal-documents', { offices: [officeId] });
    } catch (error) {
      throw error;
    }
  }

  async signVisitorsLegalDoc(documentUuid, params) {
    try {
      return await ApiClientV2.post(`/legal-documents/${documentUuid}/sign`, params);
    } catch (error) {
      throw error;
    }
  }

  async sendMessageToHost(visitorLogId) {
    try {
      return await ApiClientV2.post(`/admin/visitors/log/${visitorLogId}/notify`);
    } catch (error) {
      throw error;
    }
  }

  async visitorSignIn(params) {
    try {
      return await ApiClientV2.patch('/admin/visitors/log/bulk/update', params);
    } catch (error) {
      throw error;
    }
  }
}
