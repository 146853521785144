import React, { useContext, useEffect, useRef, useState } from 'react';
import './VisitorPage.scss';
import CardLayout from '../common/CardLayout';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Wifi } from '../../icons';
import mustache from 'mustache';
import SearchHosts from '../common/SearchHosts';
import ThanksCard from '../common/ThanksCard';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { VisitorsClient } from '../../services/http/apis/visitorsClient';
import SignatureCard from '../common/SignatureCard';
import { differenceInDays, format, isToday } from 'date-fns';
import { getUserDisplayName } from '../../services/utils';
import { captureException, setExtra } from '@sentry/react';
import { sendActionLogs } from '../../services/logs';
import { DeviceAuthContext } from '@inspace-org/react-auth';

const validationSchema = yup.object({
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  email: yup
    .string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email format')
    .required(),
  hosts: yup.string().trim().required(),
  companyName: yup.string().trim(),
  visitorType: yup.string().trim().required(),
  visitorPurpose: yup.string().trim().required(),
  legalDocumentsRequired: yup.bool(),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  hosts: '',
  companyName: '',
  visitorType: '',
  visitorPurpose: '',
  legalDocumentsRequired: true,
};

const VisitorPage = ({ settingsWifi, users }) => {
  const { settings, device, companySettings } = useContext(DeviceAuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const visitorsClient = new VisitorsClient();
  const [step, setStep] = useState(0);
  const [valueHost, setValueHost] = useState('');
  const [transitionClass, setTransitionClass] = useState('right-to-left');
  const [apiError, setApiError] = useState(null);
  const [offices, setOffices] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [officeId, setOfficeId] = useState(localStorage.getItem('VISITOR::OFFICE_ID'));
  const [buildingId, setBuildingId] = useState(localStorage.getItem('VISITOR::BUILDING_ID'));
  const [typesList, setTypesList] = useState([]);
  const [purposeList, setPurposeList] = useState([]);
  const [signImageDataUrl, setSignImageDataUrl] = useState(null);
  const [hostsList, setHostsList] = useState([]);
  const [isDisabledSignButton, setDisabledSignButton] = useState(true);
  const [visitorLog, setVisitorLog] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [documentContent, setDocumentContent] = useState({
    text: '',
    title: '',
  });
  const [documentForSign, setDocumentForSign] = useState(null);

  useEffect(() => {
    try {
      getBuildingsListWithOffices();
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (buildingList.length) {
      let currentOfficeId;
      if (buildingId) {
        currentOfficeId = buildingList.find((el) => el._id === buildingId)?.office;
        localStorage.setItem('VISITOR::OFFICE_ID', currentOfficeId);
      } else if (!buildingId && officeId && offices.length) {
        const findOfficeInList = offices.find((office) => office._id === officeId);
        currentOfficeId = findOfficeInList ? officeId : offices[0]._id;
        const newBuildingId = findOfficeInList ? findOfficeInList.defaultBuilding : offices[0].defaultBuilding;
        setBuildingId(newBuildingId);
        localStorage.setItem('VISITOR::OFFICE_ID', currentOfficeId);
        localStorage.setItem('VISITOR::BUILDING_ID', newBuildingId);
      } else {
        currentOfficeId = offices[0]._id;
        setBuildingId(offices[0].defaultBuilding);
      }
      setOfficeId(currentOfficeId);
      try {
        visitorsClient.getSettingsVisitors(currentOfficeId).then(({ items }) => {
          setTypesList(items.filter((el) => el.kind === 'type' && el.office === currentOfficeId && el.showOnKiosk));
          setPurposeList(
            items.filter((el) => el.kind === 'purpose' && el.office === currentOfficeId && el.showOnKiosk),
          );
        });
      } catch (err) {
        setExtra('Error Data', {
          status: err?.status,
          url: err?.url,
          body: err?.data,
        });
        captureException(err);
        console.error(err);
        sendActionLogs({
          errors: err,
          device,
          companySettings,
          settings,
          actionName: 'get Offices',
          actionPath: 'VisitorPage component',
        });
      }
    }
  }, [buildingList]);

  useEffect(() => {
    if (visitorLog && step === 0) {
      submitFormAndGetDoc();
    }
  }, [visitorLog]);

  const nextCard = async () => {
    setTransitionClass('right-to-left');
    if (step === 0) {
      await checkVisitorData(formik.values.email);
      return;
    }
    if (step === 7) {
      setVisitorLog(null);
    }
    if (step === 8) {
      setVisitorLog(null);
      navigate('/welcome');
    }
    setStep(step + 1);
  };

  const getBuildingsListWithOffices = async () => {
    try {
      const { offices } = await visitorsClient.getOffices();
      const { buildings } = await visitorsClient.getBuildings();
      if (offices && buildings) {
        setOffices(offices);
        const newList = buildings
          .map((building) => {
            const officeData = offices.find((office) => office._id === building.office);
            if (officeData) {
              return {
                ...building,
                officeData: officeData,
              };
            }
            return null;
          })
          .filter((el) => el)
          .sort((a, b) => (a.officeData.name > b.officeData.name ? 1 : -1));
        setBuildingList(newList);
      }
      return offices;
    } catch (err) {
      setExtra('Error Data', {
        status: err?.status,
        url: err?.url,
        body: err?.data,
      });
      captureException(err);
      console.error(err);
      sendActionLogs({
        errors: err,
        device,
        companySettings,
        settings,
        actionName: 'get Offices',
        actionPath: 'VisitorPage component',
      });
    }
  };

  const saveLegalDocument = async () => {
    const params = {
      visitorLog: visitorLog.id,
      revision: documentForSign.document.revision,
      signatureImg: signImageDataUrl,
      data: {
        NAME: `${visitorLog.visitor.firstName} ${visitorLog.visitor.lastName}`,
        DATE: format(new Date(), 'd MMM yyyy'),
      },
    };
    try {
      if (!visitorLog.signInTime) {
        finishRegistrationVisitor();
      }
      await visitorsClient.signVisitorsLegalDoc(documentForSign.documentUuid, params);
    } catch (err) {
      captureException(err);
      console.error(err);
      sendActionLogs({
        errors: err,
        device,
        companySettings,
        settings,
        actionName: 'save Legal Document',
        actionPath: 'VisitorPage component',
      });
    }
  };

  const checkVisitorData = async (email) => {
    const validateEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    if (validateEmail) {
      const params = {
        visitorEmail: encodeURIComponent(email),
        offices: [officeId],
      };
      try {
        const { items } = await visitorsClient.searchVisitorEmail(params);
        if (items && items.length) {
          const findRightVisitorData = items.find((item) => item.visitor.email === email);
          if (findRightVisitorData) {
            setHostsList(findRightVisitorData.hosts);
            setValueHost(getUserDisplayName(findRightVisitorData.hosts[0]));
            setVisitorLog(findRightVisitorData);
            formik.setValues({
              ...formik.values,
              firstName: findRightVisitorData.visitor.firstName,
              lastName: findRightVisitorData.visitor.lastName,
              email: findRightVisitorData.visitor.email,
              hosts: findRightVisitorData.hosts[0].id,
              companyName: findRightVisitorData.companyName,
              office: findRightVisitorData.office,
              visitorType: findRightVisitorData.visitorType?.id,
              visitorPurpose: findRightVisitorData.visitorPurpose?.id,
              legalDocumentsRequired: findRightVisitorData.legalDocumentsRequired,
            });
          } else {
            setStep(1);
          }
        } else {
          setStep(1);
        }
      } catch (err) {
        captureException(err);
        console.error(err);
        sendActionLogs({
          errors: err,
          device,
          companySettings,
          settings,
          actionName: 'search Visitor Email',
          actionPath: 'VisitorPage component',
        });
      }
    } else {
      formik.setFieldError('email', 'Invalid email format');
    }
  };

  const getDocumentForSign = (doc) => {
    const document = doc ? doc : documentForSign;
    const tags = [
      {
        example: `${formik.values.firstName} ${formik.values.lastName}`,
        key: 'NAME',
        title: 'Name Visitor',
        value: '{{NAME}}',
      },
      {
        example: format(new Date(), 'd MMM yyyy'),
        key: 'DATE',
        title: 'Date',
        value: '{{DATE}}',
      },
    ];
    if (document?.document?.contents) {
      const keys = {};
      tags.forEach((tag) => {
        keys[tag.key] = tag.example || '';
      });
      const contents = mustache.render(document?.document?.contents, keys, {}, { escape: (text) => text });
      setDocumentContent({
        title: document.document.title,
        text: contents,
      });
      setLoadingButton(false);
      setStep(5);
    } else {
      setLoadingButton(false);
      setStep(7);
    }
  };

  const handelBackButton = () => {
    setTransitionClass('left-to-right');
    if (step === 0) {
      setVisitorLog(null);
      navigate('/welcome');
    }
    if (step === 1) {
      setVisitorLog(null);
      formik.setValues(initialValues);
    }
    if (step === 7) {
      setStep(4);
      return;
    }
    setStep(step - 1);
  };

  const visitorNotHaveSignedDoc = (visitorData) => {
    visitorsClient
      .getLegalDocsSigning({ offices: [officeId], visitorLogs: [visitorData.id], status: 'unsigned' })
      .then((list) => {
        if (list.items.length) {
          let lastDoc = list.items[0];
          list.items.forEach((doc) => {
            if (new Date(doc.document.updatedAt).getTime() > new Date(lastDoc.document.updatedAt).getTime()) {
              lastDoc = doc;
            }
          });
          setDocumentForSign(lastDoc);
          getDocumentForSign(lastDoc);
        } else {
          setLoadingButton(false);
          if (!visitorData.signInTime) {
            finishRegistrationVisitor(visitorData);
          }
          setStep(7);
        }
      });
  };

  const finishRegistrationVisitor = async (visitorData) => {
    const data = visitorData ? visitorData : visitorLog;
    try {
      await visitorsClient.sendMessageToHost(data.id);
      await visitorsClient.visitorSignIn({
        items: [
          {
            id: data.id,
            signIn: true,
            approved: true,
          },
        ],
      });
    } catch (err) {
      captureException(err);
      console.error(err);
      sendActionLogs({
        errors: err,
        device,
        companySettings,
        settings,
        actionName: 'Error auth/check',
        actionPath: 'MainLayout component',
      });
    }
  };

  const checkSignedLegalDocumentVisitor = (visitorData, email, visitTime) => {
    try {
      visitorsClient
        .getLegalDocsSigning({
          offices: [officeId],
          visitors: [visitorData.visitor.id],
          limit: 100,
        })
        .then((visitorDocuments) => {
          visitorsClient.getLegalDocsOffice(officeId).then((officeDocuments) => {
            if (officeDocuments.items.length) {
              let lastUpdateDocumentOffice = officeDocuments.items[0];
              officeDocuments.items.forEach((item) => {
                if (new Date(item.updatedAt).getTime() > new Date(lastUpdateDocumentOffice.updatedAt).getTime()) {
                  lastUpdateDocumentOffice = item;
                }
              });
              const lastCreateOfficeDocumentId = lastUpdateDocumentOffice.uuid;
              const filterDocVisitor = visitorDocuments.items.filter(
                (visitorDoc) => visitorDoc.documentUuid === lastCreateOfficeDocumentId,
              );
              let lastVisitorDoc = filterDocVisitor[0];
              filterDocVisitor.forEach((item) => {
                if (new Date(item.signedAt).getTime() > new Date(lastVisitorDoc.signedAt).getTime()) {
                  lastVisitorDoc = item;
                }
              });
              if (lastVisitorDoc) {
                if (lastVisitorDoc?.signed) {
                  const reSignAfterDaysOfficeDoc = lastUpdateDocumentOffice.reSignAfterDays;
                  const daysAfterSignVisitorDoc = differenceInDays(
                    new Date(visitTime),
                    new Date(lastVisitorDoc.signedAt),
                  );
                  if (daysAfterSignVisitorDoc < reSignAfterDaysOfficeDoc) {
                    setLoadingButton(false);
                    if (!visitorData.signInTime) {
                      finishRegistrationVisitor(visitorData);
                    }
                    setStep(7);
                  } else {
                    visitorNotHaveSignedDoc(visitorData);
                  }
                } else {
                  visitorNotHaveSignedDoc(visitorData);
                }
              } else {
                visitorNotHaveSignedDoc(visitorData);
              }
            } else {
              visitorNotHaveSignedDoc(visitorData);
            }
          });
        });
    } catch (err) {
      captureException(err);
      console.error(err);
      sendActionLogs({
        errors: err,
        device,
        companySettings,
        settings,
        actionName: 'check Signed Legal Document Visitor',
        actionPath: 'VisitorPage component',
      });
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      const params = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        companyName: values.companyName,
        hosts: [
          {
            id: values.hosts,
          },
        ],
        office: officeId,
        building: buildingId,
        visitorType: values.visitorType,
        visitorPurpose: values.visitorPurpose,
        visitTime: new Date().toISOString(),
        legalDocumentsRequired: values.legalDocumentsRequired,
        sendEmailInvite: false,
      };
      let resultVisitorData = visitorLog;
      if (visitorLog) {
        if (!visitorLog.signInTime || !isToday(new Date(visitorLog.signInTime))) {
          try {
            await visitorsClient.editVisitor(visitorLog.id, params).then((res) => {
              setApiError(null);
              resultVisitorData = res;
              setVisitorLog(res);
            });
          } catch (err) {
            setApiError(err?.data?.message);
            setExtra('Error Data', {
              status: err?.status,
              url: err?.url,
              body: err?.data,
              payload: params,
            });
            captureException(err);
            console.error(err);
            sendActionLogs({
              errors: err,
              device,
              companySettings,
              settings,
              actionName: 'Edit visitor',
              actionPath: 'VisitorPage component',
            });
          }
        }
      } else {
        try {
          await visitorsClient.createVisitor(params).then((res) => {
            setApiError(null);
            setVisitorLog(res);
            resultVisitorData = res;
          });
        } catch (err) {
          setApiError(err?.data?.message);
          setExtra('Error Data', {
            status: err?.status,
            url: err?.url,
            body: err?.data,
            payload: params,
          });
          captureException(err);
          console.error(err);
          sendActionLogs({
            errors: err,
            device,
            companySettings,
            settings,
            actionName: 'Create visitor',
            actionPath: 'VisitorPage component',
          });
        }
      }
      if (resultVisitorData && resultVisitorData.legalDocumentsRequired) {
        checkSignedLegalDocumentVisitor(
          resultVisitorData,
          resultVisitorData.visitor.email,
          resultVisitorData.visitTime,
        );
      } else if (resultVisitorData) {
        setLoadingButton(false);
        if (!resultVisitorData.signInTime) {
          finishRegistrationVisitor(resultVisitorData);
        }
        setStep(7);
      } else {
        setLoadingButton(false);
        setStep(7);
      }
    },
  });

  const handleChangeOfficesSelector = (e) => {
    const value = e.target.value;
    setBuildingId(value);
    localStorage.setItem('VISITOR::BUILDING_ID', value);
    const findOfficeId = buildingList.find((build) => build._id === value).office;
    if (findOfficeId !== officeId) {
      setOfficeId(findOfficeId);
      formik.setFieldValue('office', findOfficeId);
      localStorage.setItem('VISITOR::OFFICE_ID', findOfficeId);
      formik.setFieldValue('visitorType', '');
      formik.setFieldValue('visitorPurpose', '');
      try {
        visitorsClient.getSettingsVisitors(findOfficeId).then(({ items }) => {
          setTypesList(items.filter((el) => el.kind === 'type' && el.office === findOfficeId && el.showOnKiosk));
          setPurposeList(items.filter((el) => el.kind === 'purpose' && el.office === findOfficeId && el.showOnKiosk));
        });
      } catch (err) {
        setExtra('Error Data', {
          status: err?.status,
          url: err?.url,
          body: err?.data,
          payload: findOfficeId,
        });
        captureException(err);
        console.error(err);
        sendActionLogs({
          errors: err,
          device,
          companySettings,
          settings,
          actionName: 'Change Offices Selector',
          actionPath: 'VisitorPage component',
        });
      }
    }
  };

  const handleChangeType = (e) => {
    formik.setFieldValue('visitorType', e.target.value);
    const findType = typesList.find((el) => el.id === e.target.value);
    if (findType) {
      formik.setFieldValue('legalDocumentsRequired', findType.legalDocsRequired);
    }
  };

  const submitFormAndGetDoc = async () => {
    setLoadingButton(true);
    await formik.submitForm();
  };

  const handleChangeFormik = (e) => {
    formik.handleChange(e);
  };

  const hasError = (field) => Boolean(formik.touched[field] && formik.errors[field]);
  const helperTextFor = (field, defaultText) => (hasError(field) ? formik.errors[field] : defaultText);

  return (
    <TransitionGroup style={{ height: '100%' }}>
      <CSSTransition key={step} classNames={transitionClass} timeout={1000}>
        <div className="form">
          {step === 0 && (
            <CardLayout
              title="guest.email_title"
              buttonText="next_btn"
              onClick={nextCard}
              onBack={handelBackButton}
              loadingButton={loadingButton}
              disabled={formik.values.email.trim() === '' || hasError('email')}
            >
              <div className="form_field">
                <input
                  className={`form_input ${hasError('email') ? 'error' : ''}`}
                  name="email"
                  placeholder={t('guest.email_label')}
                  value={formik.values.email.trim()}
                  onChange={handleChangeFormik}
                  onBlur={formik.handleBlur}
                />
                {hasError('email') && <div className="error_text">{helperTextFor('email')}</div>}
                <div className="form_note">
                  <Trans i18nKey="guest.email_note" />
                </div>
              </div>
            </CardLayout>
          )}
          {step === 1 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout
                title="guest.name_title"
                buttonText="next_btn"
                onClick={nextCard}
                onBack={handelBackButton}
                disabled={formik.values.firstName.trim() === '' || formik.values.lastName.trim() === ''}
              >
                <div className="form_field">
                  <input
                    className={`form_input ${hasError('firstName') ? 'error' : ''}`}
                    name="firstName"
                    placeholder={t('guest.name_first')}
                    value={formik.values.firstName}
                    onChange={handleChangeFormik}
                    onBlur={formik.handleBlur}
                  />
                  {hasError('firstName') && <div className="error_text">{helperTextFor('firstName')}</div>}
                </div>
                <div className="form_field">
                  <input
                    className={`form_input ${hasError('lastName') ? 'error' : ''}`}
                    name="lastName"
                    placeholder={t('guest.name_last')}
                    value={formik.values.lastName}
                    onChange={handleChangeFormik}
                    onBlur={formik.handleBlur}
                  />
                  {hasError('lastName') && <div className="error_text">{helperTextFor('lastName')}</div>}
                </div>
              </CardLayout>
            </CSSTransition>
          )}
          {step === 2 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout
                title="guest.company_title"
                buttonText="next_btn"
                onClick={nextCard}
                onBack={handelBackButton}
                disabled={hasError('companyName')}
              >
                <div className="form_field">
                  <input
                    className={`form_input ${hasError('companyName') ? 'error' : ''}`}
                    name="companyName"
                    placeholder={t('guest.company_label')}
                    value={formik.values.companyName.trim()}
                    onChange={handleChangeFormik}
                    onBlur={formik.handleBlur}
                  />
                  {hasError('companyName') && <div className="error_text">{helperTextFor('companyName')}</div>}
                </div>
              </CardLayout>
            </CSSTransition>
          )}
          {step === 3 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout
                title="guest.selects_title"
                buttonText={'next_btn'}
                onBack={handelBackButton}
                onClick={nextCard}
                disabled={
                  formik.values.visitorPurpose === '' ||
                  formik.values.visitorType === '' ||
                  formik.values.office === '' ||
                  hasError('visitorPurpose') ||
                  hasError('visitorType') ||
                  hasError('office')
                }
              >
                <div className="select">
                  <label className="select_label">
                    <Trans i18nKey={'guest.select_office_label'} />{' '}
                  </label>
                  <select
                    name="office"
                    value={buildingId}
                    className="select_field select_office"
                    onChange={handleChangeOfficesSelector}
                  >
                    {buildingList &&
                      buildingList.map((building) => (
                        <option value={building._id} key={building._id}>
                          {`${building?.officeData.name}, ${building?.name}`}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="select">
                  <label className="select_label">
                    <Trans i18nKey={'guest.select_types_label'} />
                  </label>
                  <select
                    name="visitorType"
                    className="select_field select_types"
                    required
                    value={formik.values.visitorType}
                    onChange={handleChangeType}
                  >
                    <option value="" disabled selected>
                      <Trans i18nKey={'guest.select_types_placeholder'} />
                    </option>
                    {typesList.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="select">
                  <label className="select_label">
                    <Trans i18nKey={'guest.select_purpose_label'} />{' '}
                  </label>
                  <select
                    name="visitorPurpose"
                    className="select_field select_purpose"
                    value={formik.values.visitorPurpose}
                    required
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled selected>
                      <Trans i18nKey={'guest.select_purpose_placeholder'} />
                    </option>
                    {purposeList.map((purpose) => (
                      <option value={purpose.id} key={purpose.id}>
                        {purpose.title}
                      </option>
                    ))}
                  </select>
                </div>
              </CardLayout>
            </CSSTransition>
          )}
          {step === 4 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout
                title="guest.user_title"
                buttonText={'next_btn'}
                onBack={handelBackButton}
                onClick={submitFormAndGetDoc}
                loadingButton={loadingButton}
                isSubmit
                disabled={formik.values.hosts === '' || valueHost === '' || hasError('hosts')}
              >
                <SearchHosts
                  value={valueHost}
                  helperText={helperTextFor('hosts')}
                  onChange={(value) => {
                    formik.setFieldValue('hosts', value);
                  }}
                  onBlur={formik.handleBlur}
                  error={hasError('hosts')}
                  setValueHost={setValueHost}
                  placeholder={t('guest.user_label')}
                  hostsList={hostsList}
                />
              </CardLayout>
            </CSSTransition>
          )}
          {step === 5 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout title="guest.document_title" buttonText={'next_btn'} onClick={nextCard}>
                <div className="document_title">{documentContent.title}</div>
                <div className="document_text" dangerouslySetInnerHTML={{ __html: documentContent.text }}></div>
              </CardLayout>
            </CSSTransition>
          )}
          {step === 6 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout
                title="guest.signature_title"
                buttonText={'next_btn'}
                onClick={() => {
                  saveLegalDocument();
                  setStep(step + 1);
                }}
                disabled={!signImageDataUrl || isDisabledSignButton}
              >
                <SignatureCard onSubmit={setSignImageDataUrl} setDisabledSignButton={setDisabledSignButton} />
              </CardLayout>
            </CSSTransition>
          )}
          {step === 7 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <ThanksCard
                user={valueHost}
                onClick={nextCard}
                onBack={handelBackButton}
                text="guest.thanks_text"
                error={apiError}
              />
            </CSSTransition>
          )}
          {step === 8 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout title="" buttonText={'next_btn'} onClick={nextCard}>
                <div className="wifi">
                  <div className="wifi_icon">
                    <Wifi />
                  </div>
                  <div className="wifi_title">
                    <Trans i18nKey="guest.wifi_title" />
                  </div>
                  <div className="wifi__info">
                    <div className="wifi_list">
                      <div className="wifi_label">
                        <Trans i18nKey="guest.wifi_network" />
                      </div>

                      <div className="wifi_value">
                        {settingsWifi?.ssid || <Trans i18nKey="guest.wifi_network_value" />}
                      </div>
                    </div>
                    <div className="wifi_list">
                      <div className="wifi_label">
                        <Trans i18nKey="guest.wifi_password" />
                      </div>
                      <div className="wifi_value wifi_password">
                        {settingsWifi?.password || <Trans i18nKey="guest.wifi_password_value" />}
                      </div>
                    </div>
                  </div>
                </div>
              </CardLayout>
            </CSSTransition>
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default VisitorPage;
